.link {
  width: rem(44px);
  height: rem(44px);
  border-radius: var(--mantine-radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  margin: auto;

  @media (min-width: em(460px)) {
    /* margin-bottom: var(--mantine-spacing-sm); */

    &:hover {
      background: light-dark(
        var(--mantine-color-gray-0),
        var(--mantine-color-dark-5)
      );
    }
  }

  &[data-active="true"] {
    &,
    &:hover {
      background-color: var(--mantine-color-blue-light);
      color: var(--mantine-color-blue-light-color);
    }
  }
}
