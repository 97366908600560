.imageContainer {
  overflow: hidden;
  padding: var(--mantine-spacing-sm);
  position: relative;
  min-height: rem(152px);
  border-radius: var(--mantine-spacing-sm);
}

.image {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 110%;
  border-radius: var(--mantine-radius-sm);
  transform: translate3d(-50%, -50%, 0);
}
