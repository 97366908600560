.item {
  width: rem(90px);
  height: rem(90px);
  background: light-dark(
    var(--mantine-color-gray-0),
    var(--mantine-color-dark-6)
  );
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: var(--mantine-spacing-xs);
  transition: background-color 0.2s;
}

.text {
  font-size: var(--mantine-fontSizes-sm);
}
