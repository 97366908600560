.container {
  display: block;
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 10px 0 10px rgb(0 0 0 / 20%);

  &[data-visible="false"] {
    display: none;
  }
}

.content {
  padding: var(--mantine-spacing-sm);

  @media (min-width: $mantine-breakpoint-lg) {
    padding: var(--mantine-spacing-lg);
  }

  @media (min-width: $mantine-breakpoint-xl) {
    padding: var(--mantine-spacing-lg);
  }
}

.videoInformationsContainer {
  max-width: rem(100px);

  @media (min-width: $mantine-breakpoint-sm) {
    max-width: rem(320px);
    overflow: hidden;
  }

  @media (min-width: $mantine-breakpoint-md) {
    max-width: rem(260px);
  }

  @media (min-width: $mantine-breakpoint-lg) {
    max-width: rem(320px);
  }

  @media (min-width: 2100px) {
    max-width: rem(440px);
  }
}

.thumbnail {
  flex: 0 0 50px;
  height: rem(50px);
  border-radius: var(--mantine-radius-md);

  @media (max-width: $mantine-breakpoint-lg) {
    display: none;
  }
}

.spacer {
  height: 0;

  @media (min-width: $mantine-breakpoint-md) {
    &[data-visible="true"] {
      height: rem(78px);
    }
  }

  @media (min-width: $mantine-breakpoint-lg) {
    &[data-visible="true"] {
      height: rem(98px);
    }
  }

  @media (min-width: $mantine-breakpoint-xl) {
    &[data-visible="true"] {
      height: rem(128px);
    }
  }

  @media (min-width: rem(2140)) {
    display: none;
  }
}
