body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow: hidden;
}

html {
  height: -webkit-fill-available;
}

.App {
  height: 100vh;
  flex-direction: column;
}

.App.iOS {
  height: -webkit-fill-available;
}

/* This "static" media will be updated after upgrade Mantine version to 7 with CSS module */
@media screen and (min-width: 648px) {
  .App {
    flex-direction: row;
  }
}

.App-Content {
  overflow-y: auto;
  flex: 1;
  max-height: 100vh;
}
